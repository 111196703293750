














































































































import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import SuggestionItem from "@/components/suggestion/base-suggestion/SuggestionItem.vue";
import SuggestionSelectedItem from "@/components/suggestion/base-suggestion/SuggestionSelectedItem.vue";
import { SuggestionModel, SuggestionType } from "./SuggestionModel";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

@Component({
    components: {
        SuggestionItem,
        SuggestionSelectedItem,
        VuePerfectScrollbar
    },
    directives: {
        "click-outside": {
            bind: function (el: any, binding: any, vNode: any) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== "function") {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                    if (compName) {
                        warn += `Found in component '${compName}'`;
                    }
                }
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble;
                const handler = (e: any) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;

                // add Event Listeners
                document.addEventListener("click", handler);
            },

            unbind: function (el: any, binding) {
                // Remove Event Listeners
                document.removeEventListener("click", el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            }
        }
    }
})
export default class Suggestion extends Vue {
    @Ref("searchInput") searchInput!: any;
    @Prop({default: SuggestionType.input}) type!: string; // popup | input
    @Prop({default: ""}) value!: string;
    @Prop({default: ""}) label!: string;
    @Prop({default: false}) allowAddWhenNotExists!: boolean;
    @Prop({default: ""}) placeholder!: string; // popup | input
    @Prop({default: ""}) notFoundText!: string;
    @Prop({default: ""}) addLabel!: string;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: false}) showAddButton!: boolean;
    @Prop({default: false}) showAvatar!: boolean;
    // @Prop({default: true}) isShowSelected!: boolean;
    @Prop({default: () => []}) listSelected!: SuggestionModel[];
    @Prop({default: () => []}) data!: SuggestionModel[];
    @Prop({default: 0}) totalPage!: number;
    @Prop({default: false}) isLoading!: number;
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
    };
    isShow = false;
    searchTerm: string = "";
    isSearching = false;
    selectedIndex: number | null = null;
    // listSelected: SuggestionModel[] = [];
    suggestionType = SuggestionType;
    currentPage = 1;
    _showListSelected = false;
    _showSearchInput = true;

    selecteds: SuggestionModel[] = this.listSelected;

    // set selecteds(value: any) {
    //     this.selecteds = value;
    // }

    @Watch("isShow")
    onShow(value: boolean) {
        if (value) {
            this.focusSearchInput();
            this.selecteds = cloneDeep(this.listSelected);
        }
        this.$emit('shown', value)
    }

    @Watch("searchTerm")
    onSearchTermChange(value: string) {
        this.searchDebounce();
    }

    @Watch('currentPage')
    onPageChange(value: number) {
        this.$emit('pageChanged', value);
    }

    @Watch('isLoading')
    onLoading(value: boolean) {
        // if (value) {
        //     this.$vs.loading();
        // } else {
        //     this.$vs.loading.close();
        // }
    }

    @Watch('listSelected')
    onListSelectedChange(newValue: any, oldValue: any) {
        this.selecteds = cloneDeep(newValue);
    }

    @Emit("searched")
    emitSearch() {
        this.selectedIndex = null;
        return cloneDeep(this.searchTerm !== undefined ? this.searchTerm.trim() : '');
    }

    @Emit('select')
    emitSelectItem(selectedItems: SuggestionModel[]) {
        return selectedItems;
    }

    @Emit('input')
    emitValue(value: any) {
        return value;
    }

    @Emit("selectedItemRemoved")
    emitRemoveSelectedItem(suggestion: SuggestionModel) {
        return suggestion;
    }

    @Emit("accepted")
    emitAccepted() {
        this.isShow = false;
        this.searchTerm = '';
        return this.selecteds;
    }

    @Emit("add")
    emitAdd() {
        return this.searchTerm;
    }

    get showAdd() {
        return this.data.length === 0 && this.searchTerm.length > 0
            && this.allowAddWhenNotExists;
    }

    get showListSelected() {
        if (this.isMultiple) {
            return true;
        } else {
            return this.selecteds !== null && this.selecteds !== undefined && this.selecteds.length > 0 && !this.isShow;
        }
    }

    get showSearchInput() {
        if (this.isMultiple) {
            return true;
        } else {
            return !this.showListSelected;
        }
    }

    set showListSelected(value: boolean) {
        this._showListSelected = value;
    }

    searchDebounce: any = debounce(() => {
        this.emitSearch();
    }, 500);

    onSelected(userSuggestion: SuggestionModel) {
        if (!this.isMultiple) {
            this.isShow = false;
        }
        this.selectItem(userSuggestion);
    }

    onSelectSelectedItem(suggestion: SuggestionModel) {
        if (!this.isMultiple) {
            this.isShow = true;
        }
    }

    async onRemoveSelectedItem(suggestion: SuggestionModel) {
        // const listSelectedItem = await this.removeItem(suggestion);
        const item = this.data.find((dataItem: SuggestionModel) => {
            return dataItem.id === suggestion.id;
        });
        if (item) {
            this.selectItem(item);
        }
        this.emitRemoveSelectedItem(suggestion);
    }

    show(isShow = true) {
        this.isShow = isShow;
    }

    clickOutside() {
        if (this.type === SuggestionType.input) {
            this.isShow = false;
        }
    }

    onKeyDown(event: any) {
        const navigationKeys = [
            "ArrowLeft",
            "ArrowRight",
            "ArrowUp",
            "ArrowDown"
        ];
        const selectionKeys = ["Enter", "Tab", "NumpadEnter"];
        const code = event.code;
        if (
            selectionKeys.indexOf(code) > -1 ||
            selectionKeys.indexOf(code) > -1
        ) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            if (this.showAdd) {
                this.emitAdd();
            } else {
                if (this.selectedIndex != null) {
                    const selectedSuggestion = this.data[this.selectedIndex];
                    this.selectItem(selectedSuggestion);
                }
            }
            return;
        }
        if (navigationKeys.indexOf(code) > -1) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            this.navigate(code);
            return;
        }
        if (selectionKeys.indexOf(code) > -1) {
            if (this.selectedIndex == null) {
                return;
            }
            const selectedUser = this.data[this.selectedIndex];
            this.selectItem(selectedUser);
            return;
        }
        if (code === "Backspace") {
            this.backspaceKeyDown();
            return;
        }
        if (code === "Escape") {
            this.isShow = false;
            if (!this.isMultiple) {
                this.isShow = false;
            }
        }

        const value = event.target != null ? event.target.value : '';
        this.searchTerm = value;
        this.searchDebounce();
    }

    private backspaceKeyDown() {
        if (this.searchTerm.length === 0) {
            const lastIndex = this.selecteds.length - 1;
            const selectedUser = this.selecteds[lastIndex];
            this.onRemoveSelectedItem(selectedUser);
        }
    }

    private checkIsSelected(id: string): boolean {
        const index = this.selecteds.findIndex((item: SuggestionModel) => {
            return item.id === id;
        });
        return index !== -1;
    }

    private navigate(code: string) {
        if (!this.isShow) {
            this.isShow = true;
        }
        if (code === "ArrowDown" || code === "ArrowRight") {
            this.moveDown();
        } else {
            this.moveUp();
        }
    }

    private moveDown() {
        this.setSelectedIndex();
    }

    private moveUp() {
        this.setSelectedIndex(false);
    }

    private setSelectedIndex(isDown: boolean = true) {
        const lengthIndex = this.data.length - 1;
        if (this.selectedIndex == null) {
            this.selectedIndex = isDown ? 0 : this.data.length - 1;
        } else if (this.selectedIndex === lengthIndex) {
            this.selectedIndex = isDown ? 0 : this.selectedIndex - 1;
        } else if (this.selectedIndex === 0) {
            this.selectedIndex = isDown ? this.selectedIndex + 1 : lengthIndex;
        } else {
            this.selectedIndex = isDown
                ? this.selectedIndex + 1
                : this.selectedIndex - 1;
        }
        this.data.forEach((suggestion: SuggestionModel, index: number) => {
            suggestion.isActivated = index === this.selectedIndex;
        });
    }

    private focusSearchInput() {
        setTimeout(() => {
            if (this.type === SuggestionType.input) {
                this.searchInput.focus();
            } else {
                this.searchInput.$el.querySelector('input').focus();
            }
        }, 200);
    }

    private selectItem(suggestion: SuggestionModel) {
        if (this.isMultiple) {
            suggestion.isSelected = !suggestion.isSelected;
            if (!suggestion.isSelected) {
                const index = this.selecteds.findIndex(
                    (item: SuggestionModel) => {
                        return suggestion.id === item.id;
                    }
                );
                this.$delete(this.selecteds, index);
            } else {
                this.selecteds.push(suggestion);
            }
            this.emitSelectItem(this.selecteds);
        } else {
            this.$delete(this.selecteds, 0);
            this.selecteds.push(suggestion);
            this.emitValue(suggestion.id);
            this.emitSelectItem(this.selecteds);
            this.isShow = false;
        }
    }

    private async removeItem(
        suggestion: SuggestionModel
    ): Promise<SuggestionModel[]> {
        if (this.isMultiple) {
            const index = this.selecteds.findIndex(
                (item: SuggestionModel) => {
                    return item.id === suggestion.id;
                }
            );
            if (index != -1) {
                const user = this.data.find((item: SuggestionModel) => {
                    return item.id === suggestion.id;
                });
                if (user) {
                    user.isSelected = false;
                }
                this.$delete(this.selecteds, index);
            }
            return this.selecteds;
        } else {
            this.selecteds = [];
            this.isShow = true;
            return this.selecteds;
        }
    }
}
