













import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { UserSuggestionModel } from "@/components/suggestion/user-suggestion/UserSuggestionModel";
import {SuggestionModel} from "@/components/suggestion/base-suggestion/SuggestionModel";

@Component
export default class SuggestionSelectedItem extends Vue {
    @Prop() item!: SuggestionModel;
    @Prop({default: false}) showAvatar!: boolean;

    @Emit("remove")
    emitRemove() {
        return this.item;
    }

    @Emit("select")
    emitSelect(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        return this.item;
    }
}
