










import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { SuggestionModel } from "./SuggestionModel";
import * as _ from "lodash";

@Component({
    components: {}
})
export default class SuggestionItem extends Vue {
    @Prop() item!: SuggestionModel;
    @Prop({ default: true }) isMultiple!: boolean;
    @Prop({ default: false }) showAvatar!: boolean;

    model = new SuggestionModel();

    @Emit("select")
    emitSelect() {
        return this.item;
    }

    mounted() {
        this.model = _.cloneDeep(this.item);
    }

    get isActivated() {
        return this.item && this.item.isActivated;
    }
}
